// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import Rails from '@rails/ujs'
import Plyr from 'plyr'

Rails.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var heroAnimationTimer = null

document.addEventListener("turbo:load", () => {
  let menuButton = document.querySelector("header.main-header div.menu-button")
  let header = menuButton.closest("header.main-header")
  let mainHtml = document.querySelector("html")
  
  menuButton.onclick = (e) => {
    e.stopPropagation()
    
    if(menuButton.classList.contains("open")) {
      menuButton.classList.remove("open")
      header.classList.remove("mobile-nav")
      mainHtml.classList.remove("nav-open")
    } else {
      menuButton.classList.add("open")
      header.classList.add("mobile-nav")
      mainHtml.classList.add("nav-open")
    }
  }
  
  animateMainHero()
  initSearchField()
  initMediaPlayer()
  
  // if(typeof(ga) == 'function') {
  //   ga('send', 'pageview', window.location.pathname)
  // }
})

document.addEventListener("DOMContentLoaded", () => {
  document.addEventListener("turbo:before-cache", () => {
    let menuButton = document.querySelector("header.main-header div.menu-button")
    let header = menuButton.closest("header.main-header")
    let mainHtml = document.querySelector("html")
    let mainHero = document.querySelector("section.hero")
    
    menuButton.classList.remove("open")
    header.classList.remove("mobile-nav")
    mainHtml.classList.remove("nav-open")
    mainHero.classList.remove("finished")
    mainHero.querySelectorAll("div.row div.image").forEach((element) => {
      element.classList.remove("current")
    })
    header.querySelectorAll("header ul.main-nav li.multiple").forEach((element) => {
      element.classList.remove("active")
    })
    header.querySelector("header ul.main-nav").classList.remove("sub-menu")
    
    clearTimeout(heroAnimationTimer)
  })
  
  initNavMenus()
  disableFormEnter()
  initExpandBoxes()
  initOnChangeSubmit()
})

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function animateMainHero() {
  let heroSection = document.querySelector("section.hero")
  let images = document.querySelectorAll("section.hero div.images div.row div.image")
  let row = document.querySelector("section.hero div.images div.row")
  var shuffled_images = null
  clearTimeout(heroAnimationTimer)
  
  if (images.length == 0) {
    return false
  }
  
  let image_array = Array.from(images)
  shuffled_images = shuffle(image_array)
  
  images.forEach((element, index) => {
    element.remove()
  })

  shuffled_images.forEach((element, index) => {
    element.classList.remove("current")
    row.appendChild(element)
  })

  heroSection.setAttribute("data-loaded", true)
  
  shuffled_images[shuffled_images.length - 1].classList.add("current")
  
  let animateImages = function() {
    let rotateImages = (images) => {
      let heroAnimationTimer = setTimeout(() => {
        var firstImage = row.querySelector("div.image:first-child")
        let currentImage = row.querySelector("div.image:last-child")
        firstImage.classList.add("current")
      
        row.appendChild(firstImage)
        
        setTimeout(() => {
          currentImage.classList.remove("current")
        }, 4000)
        
        rotateImages(images)
      }, 4000 + Math.floor(Math.random() * 1800))
    }
    
    rotateImages(shuffled_images[0])
  }
  
  let loadImage = new Image()
  loadImage.src = shuffled_images[0].querySelector("img").src
  loadImage.addEventListener('load', () => {
    animateImages()
    setTimeout(()=> {
      heroSection.classList.add("finished")
    }, 1200)
  })
}

function initNavMenus() {
  document.addEventListener("click", function(e) {
    if (e.target.matches("header ul.main-nav li.multiple span.multiple")) {
      let rootElement = e.target.closest("ul.main-nav")
      let parentElement = e.target.closest("li.multiple")
      
      if (parentElement.classList.contains("active")) {
        parentElement.classList.remove("active")
        rootElement.classList.remove("sub-menu")
      } else {
        parentElement.classList.add("active")
        rootElement.classList.add("sub-menu")
      }
    }
  })
}

function disableFormEnter() {
  document.addEventListener("keydown", function(e) {
    let code = e.keyCode || e.which
    
    if (code == 13 && e.target.closest("form.disable-key-submit") && !e.target.matches("textarea")) {
      e.preventDefault()
      return false
    }
  })
}

function initExpandBoxes() {
  document.addEventListener("click", function(e) {
    if (e.target.matches("div.expand-box a.button")) {
      e.preventDefault()
      
      let box = e.target.closest("div.expand-box")
      
      if(!box.classList.contains("active")) {
        box.classList.add("active")
      } else {
        box.classList.remove("active")
      }
    }
  })
}

function initOnChangeSubmit() {
  document.addEventListener("change", function(e) {
    if (e.target.matches(".submit-on-change")) {
      e.preventDefault()
      let form = e.target.closest("form")
      Rails.fire(form, 'submit')
    }
  })
  
  document.addEventListener("click", function(e) {
    if (e.target.matches(".submit-on-toggle-radio")) {
      e.preventDefault()
      let input = e.target.querySelector("input")
      let form = e.target.closest("form")
      
      if (input.checked) {
        input.checked = false
      } else {
        input.checked = true
      }
      
      Rails.fire(form, 'submit')
    }
  })
}

function initSearchField() {
  let searchFields = document.querySelectorAll("form div.search-field")
  
  searchFields.forEach((element) => {
    let textField = element.querySelector("input.text-field")
    let resetButton = element.querySelector("button.clear-button")
    
    textField.addEventListener("keyup", function(e) {
      if(this.value) {
        if(!element.classList.contains("active")) {
          element.classList.add("active")
        }
      } else {
        element.classList.remove("active")
      }
    })
    
    resetButton.addEventListener("click", function(e) {
      e.preventDefault()
      textField.value = ""
      element.classList.remove("active")
      Rails.fire(element.closest("form"), 'submit')
    })
  })
}

function initMediaPlayer() {
  const player = new Plyr('#media-player', {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'airplay', 'fullscreen'],
    fullscreen: { enabled: true, fallback: true, iosNative: true }
  })

  document.addEventListener("turbo:before-cache", () => {
    player.destroy()
  })
}